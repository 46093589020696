/* @import './components/navbar/Nav.css'; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-color: #0c0c0c;
  --primary-color: #0d0d0d;
  --secondary-color: #fff;
  --text-color: #fff;
  --text-color-2: #fff;
  --overlay-color: rgb(12 12 12 / 63%);
  --inverted: 1;
}

[data-theme="light"] {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #000;
  --text-color: #000;
  --text-color-2: #000;
  --overlay-color: rgb(255 255 255 / 70%);
  --inverted: 0;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color);
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Marcellus;
}

a,
a:hover {
  color: var(--text-color);
}

p {
  word-break: break-word;
  hyphens: auto;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  filter: invert(1);
  background-color: var(--primary-color) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

html {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.page-exit {
  opacity: 0;
  width: 0;
}

.page-exit-active {
  opacity: 0;
  width: 0;
}

.page-exit-done {
  opacity: 0;
  width: 0;
}

/* .page-enter {
  opacity: 0;
  width: 0;
}
.page-enter-active {
  opacity: 0;
  width: 0;
}
.page-enter-done {
  opacity: 0;
  width: 0;
} */
section {
  flex: 1 0 auto;
  position: relative;
  width: 100%;
  -webkit-transition: all .5s ease-in;
  -o-transition: all .5s ease-in;
  transition: all .5s ease-in;
}

.who_am_I {
  font-family: Cinzel;
}

.has-first-color {
  color: var(--primary-color);
}

.btn-portfolio {
  background: var(--primary-color);
  border-radius: 0;
}

.btn-portfolio a {
  color: #000;
  text-decoration: none;
}

.btn-about a {
  color: var(--text-color);
  text-decoration: none;
}

.intro_sec {
  height: calc(100vh - 60px);
  min-height: 90vh;
  /* min-height: 700px; */
  height: 100vh;
  margin-top: -60px;
}

@media (max-width:991.98px) {
  .intro_sec {
    display: block;
    /* height: auto!important; */
    height: auto;
  }
}

.intro_sec .text,
.intro_sec .h_bg-image {
  width: 50%;
}

@media (max-width:991.98px) {

  .intro_sec .text,
  .intro_sec .h_bg-image {
    width: 100%;
  }
}

.intro_sec .intro {
  max-width: 450px;
  margin: 0 auto;
  padding-left: 30px;
}

.intro_sec .intro-particles {
  /* max-width: 450px;
  margin: 0 auto;
  padding-left: 30px; */
}

@media (max-width:991.98px) {
  .intro_sec .intro {
    max-width: 700px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .intro_sec .intro-particles {
    max-width: 800px;
    /* padding-left: 20px;
      padding-right: 20px; */
  }
}

.intro_sec .intro .feature .wrap-icon {
  background: 0 0 !important;
  width: auto;
  height: auto;
  margin-bottom: 0;
}

.intro_sec .intro .feature .wrap-icon svg {
  color: #5cccc9;
}

.intro_sec .text h1 {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: 700;
}

.intro_sec .text h3 {
  font-size: 16px;
  font-weight: 700;
}

.intro_sec .h_bg-image {
  /* background-size: cover;
  background-position: center; */
  /* vertical-align: middle; */
  /* min-height: 700px; */
  /* position: absolute;
  top: 25%;
  left: 50%; */
}

.ac_btn {
  padding: 4px 19px;
  color: var(--secondary-color);
  position: relative;
  border: var(--secondary-color) 2px solid;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
  cursor: pointer;
  border-radius: 0;
  margin-right: 20px;
  min-width: 112px;
}

.ac_btn a {
  text-decoration: none;
}

.ac_btn:hover {
  box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
  transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
  width: 100%;
  height: 100%;
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
  background-color: #000;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
  z-index: -4;
}

.ac_btn .two {
  background-color: var(--primary-color);
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
}

.ac_btn .three {
  background-color: var(--secondary-color);
  z-index: -2;
  transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -3;
}

#button_p {
  background: var(--secondary-color);
  color: var(--primary-color);
}

#button_h:hover {
  color: var(--primary-color)
}

.intro_sec .h_bg-image .block {
  bottom: 50vh;
  max-width: 220px;
  padding: 30px;
  left: -110px;
  position: absolute;
  width: 250px;
  height: 220px;
  border-radius: 100%;
  /* mix-blend-mode: multiply; */
  background-color: #2f514e;
  z-index: 9999999999;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (max-width:991.98px) {
  .intro_sec .h_bg-image {
    position: unset;
    height: 600px;
    min-height: 75vh;
    margin-bottom: 30px;
  }

  .intro_sec .h_bg-image .block {
    top: unset;
    bottom: 0;
    width: 100%;
    margin: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
}

.intro_sec .h_bg-image .block .wrap-icon {
  font-size: 40px;
  color: var(--text-color);
  margin-bottom: 10px;
  display: block;
}

.intro_sec .h_bg-image .block h3 {
  font-size: 14px;
  color: var(--text-color);
  letter-spacing: .1rem;
  font-weight: 700;
  margin-bottom: 15px;
}

.intro_sec .h_bg-image .block p {
  font-size: 14px;
  line-height: 1.3;
  color: var(--text-color)
}

.intro_sec .h_bg-image {
  filter: saturate(0.5);
}

.center {
  width: 50%;
  /* background-size: cover;
  background-position: center; */
  /* vertical-align: middle; */
  /* min-height: 700px; */
  position: absolute;
  top: 25%;
  left: 50%;
  /* text-align:center; */
}

@media (max-width:991.98px) {
  .center {
    position: unset;
    top: unset;
    left: unset;
    /* margin: auto; */
    width: auto;
    /* border: 3px solid green; */
    /* padding: 10px; */
    text-align: center;
  }
}


@font-face {
  font-family:'doboto';
  font-style: normal;
  font-weight: 700;
  src: local('Doboto'), url('https://fonts.cdnfonts.com/s/21577/Doboto Bold.woff') format('woff');
  font-display: swap;
}